<template>
  <BlogsPage />
</template>

<script>
  import BlogsPage from '@/components/view/BlogsPage';

  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
      BlogsPage,
    },
    metaInfo: {
      title: 'Welkom bij KantoorFlex | flexplekken in Nederland ',
      htmlAttrs: {
        lang: 'EN',
        amp: true,
      },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content: 'Handige blogs op met kantoorflex Nederland.',
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  };
</script>
