<template>
  <div>
    <img src= @/assets/banner.jpg height="200" width="100%" />
    <v-container>
      <v-row>
        <v-col class="d-flex flex-column" sm="6" md="4">
          <v-card
            class="pb-2 flex d-flex flex-column"
            max-width="400"
            v-for="item in items"
            :key="item.blog_id"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
            >
              <v-card-title>{{ item.title }}</v-card-title>
            </v-img>

            <v-card-text class="text--primary">
              <div>{{ item.blog }}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="#64b5f6" text> Delen </v-btn>

              <v-btn color="#64b5f6" text> Bekijken </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "../api/api";
export default {
  name: "BlogsPage",
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    try {
      const data = await api.getBlogs();
      this.items = data;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
